<template>
  <el-form-item v-if="hasPrevious || hasNext" style="padding-top: 4px; margin:auto;">
    <el-button-group>
      <el-button
          type="primary"
          @click="onPrevious"
          :disabled="!hasPrevious"
      >
        <el-icon :class="leftIconClass"><ArrowLeft/></el-icon>
        {{ previousLabel }}
      </el-button>
      <el-button
          type="primary"
          @click="onNext"
          :disabled="!hasNext"
      >
        {{ nextLabel }}
        <el-icon :class="rightIconClass"><ArrowRight/></el-icon>
      </el-button>
    </el-button-group>
  </el-form-item>
</template>

<script>
export default {
  props: {
    leftIconClass: String,
    rightIconClass: String,
    previousLabel: String,
    nextLabel: String,
    hasPrevious: Boolean,
    hasNext: Boolean,
    onPrevious: Function,
    onNext: Function
  }
};
</script>
