<template>
  <el-form-item :style="formItemStyle" :label="label">
    <el-radio-group v-model="selectedPageSize" @change="handleChange">
      <el-radio-button v-for="option in options" :key="option.label" :label="option.value">
        {{ option.label }}
      </el-radio-button>
    </el-radio-group>
  </el-form-item>
</template>

<script>
export default {
  props: {
    label: String,
    pageSize: String,
    options: Array,
    formItemStyle: String,
    onChange: Function
  },
  data() {
    return {
      selectedPageSize: this.pageSize || (this.options.length > 0 ? this.options[0].value : null)
    };
  },
  mounted() {
    if (!this.pageSize && this.options.length > 0) {
      this.handleChange();
    }
  },
  methods: {
    handleChange() {
      this.onChange(this.selectedPageSize);
    }
  }
};
</script>
