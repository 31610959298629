<script setup>
import {to} from "await-to-js";
import {onMounted, reactive, ref, computed} from "vue";
import {set, isEmpty, omitBy} from "lodash";
import {interceptor} from "@dutypay/utilities";
import {useEnvStore} from "@dutypay/store-modules";
import {ElNotification} from "element-plus";

const loading = ref(false);
const userCompanyM2M = ref([]);
const queriedUserCompanyM2MCount = ref(0);
const totalUserCompanyM2MCount = ref(0);
const envStore = useEnvStore();
import {useRoute, useRouter} from 'vue-router';

const route = useRoute();
const router = useRouter();
const hubsystemUserID = route.params.userID;

// Check if something goes wrong about params
if (isEmpty(hubsystemUserID)) {
  router.push('/users');
}

const pageSizeOptions = ref([
  {label: '10', value: '10'},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  searchCompanyName: "",
  pageNumber: "1",
  "hs.user.id": hubsystemUserID
});

// TODO: Workaround required because envStore.apiUrls.dutypay is null in local version
const globalUsedUrl = isEmpty(envStore.apiUrls.dutypay) ? 'http://api.dutypay.local:3000' : envStore.apiUrls.dutypay;

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getUserCompanyM2M()
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber, 10) > 1;
});

async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) - 1).toString();
  await getUserCompanyM2M();
}

const hasNextPage = computed(() => {
  return (
      queriedUserCompanyM2MCount.value < totalUserCompanyM2MCount.value)
});

async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) + 1).toString();
  await getUserCompanyM2M();
}

// TODO: Needs an improvement about permission properties if refactoring is done
async function updateUserCompanyM2M(data) {
  loading.value = true;
  let updatedBody = {}
  updatedBody = set(updatedBody, 'deleted', data.isLinkInactive === true ? 1 : 0)

  try {
    let err, res;
    [err, res] = await to(
        interceptor.patch(`${globalUsedUrl}/api/v0/user-company-relations/${data.id}`, updatedBody)
    );
    if (err) {
      ElNotification({
        title: "Error to update user-company-m2m",
        type: "error",
        message: err.message
      });
    } else {
      console.log("res", res.data)
      ElNotification({
        title: res.data.name,
        type: "success",
        message: res.data.message
      });
    }
    loading.value = false;
  } catch (err) {
    ElNotification({
      title: "Update cancelled",
      type: "error",
      message: err.message
    });
    loading.value = false;
  }
}

async function refreshUserCompanyM2M() {
  console.log("refreshUserCompanyM2M")
  loading.value = true;
  let err, res;
  const filteredParameters = omitBy(parameters, value => isEmpty(value));

  [err, res] = await to(
      interceptor.post(`${globalUsedUrl}/api/v0/user-company-relations/${hubsystemUserID}`)
  );

  if (err) {
    ElNotification({
      title: "Error to refresh user-company-m2m",
      type: "error",
      message: err.message
    });

  } else {
    ElNotification({
      title: res.data.name,
      type: "success",
      message: res.data.message
    });
  }
  await getUserCompanyM2M();
  loading.value = false;
}


async function getUserCompanyM2M() {
  console.log("getUserCompanyM2M")
  loading.value = true;
  let err, res;
  const filteredParameters = omitBy(parameters, value => isEmpty(value));

  [err, res] = await to(
      interceptor.get(`${globalUsedUrl}/api/v0/user-company-relations`, {
        params: filteredParameters,
      })
  );

  if (err) {

    if (err.response.data.name === "Not Found Error") {
      userCompanyM2M.value = [];
    } else {
      loading.value = false;
      throw err;
    }
  } else {
    //userCompanyM2M.value = res.data.result.userCompanyM2MList;
    userCompanyM2M.value = res.data.result.userCompanyM2MList.map(item => {
      console.log("item.isLinkInactive", item.isLinkInactive)
      return {
        ...item,
        isLinkInactive: item.isLinkInactive === 1
      };
    });
    queriedUserCompanyM2MCount.value = res.data.result.sqlQueriedUserCompanyM2MCurrentCount;
    totalUserCompanyM2MCount.value = res.data.result.sqlQueriedUserCompanyM2MTotalCount;
  }
  loading.value = false;
}

onMounted(async () => {
  await getUserCompanyM2M();
});
</script>

<script>
import PaginationNavigationComponent from '../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "UserCompanyRelationPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>User-Company Relations based on User {{ hubsystemUserID }}</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="16" :xl="16" :lg="16" :sm="32" :xs="32" class="searchform">
        <el-form-item style="padding-left: 1rem; flex-grow: 1">
          <el-input
              v-model="parameters.searchCompanyName"
              @change="parameters.pageNumber = '1'"
              placeholder="Search for path.name"
              :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getUserCompanyM2M"> Search</el-button>
          <el-button type="success" @click="refreshUserCompanyM2M"> Update possible linked companies</el-button>
        </el-form-item>
      </el-col>
      <el-col
          :span="8"
          :xl="8"
          :lg="8"
          :sm="24"
          :xs="24"
          class="pagesize"
          style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="UserCompanyM2M per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="userCompanyM2M" stripe style="width: 100%">
          <!--<el-table-column prop="hs.infrastructure.component.id" label="Infrastructure Component ID"/>-->
          <el-table-column prop="id" label="User-Company-M2M ID"/>
          <el-table-column prop="hs.user.id" label="Hubsystem User ID"/>
          <el-table-column prop="hs.company.id" label="Hubsystem Company ID"/>
          <el-table-column prop="legal.name" label="Legal name"/>
          <el-table-column prop="customer.number" label="Customer Number"/>
          <el-table-column prop="isLinkInactive" label="isLinkInactive">
            <template #default="scope">
              <el-checkbox v-model="scope.row.isLinkInactive"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="updateUserCompanyM2M(scope.row)"
              >Save
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
{
  "en-US": {
  }
}
</i18n>
