<template>
  <el-config-provider :locale="elementLocale" :zIndex="3000" v-if="isAuth">
    <el-container>
      <el-aside width="300px" class="admin-aside"
        ><el-scrollbar>
          <el-menu
            class="admin-aside-menu"
            background-color="#333"
            text-color="#fff"
            active-text-color="#fff"
            :router="true"
          >
            <div class="admin-logo-wrapper">
              <img
                :src="`${apiUrls.dutypay}/api/v0/assets/logo/dutypay/standard/light/svg`"
                class="header-bar__application-logo speak-never"
                style="height: 30px; padding: 10px; width: auto"
                alt="Dutypay"
              />
              <h2>&nbsp;Admin</h2>
            </div>
            <el-menu-item index="/users"
              ><el-icon><User /></el-icon>Users</el-menu-item
            >
            <el-menu-item index="/frontend-pages"
            ><el-icon><Platform /></el-icon>Frontend-Pages</el-menu-item
            >
            <el-menu-item index="/api-routes"
            ><el-icon><MapLocation /></el-icon>API-Routes</el-menu-item
            >
            <el-menu-item index="/companies"
              ><el-icon><OfficeBuilding /></el-icon>Companies</el-menu-item
            >
            <el-sub-menu index="/pbac">
              <template #title><el-icon><Lock /></el-icon> PBAC</template>
              <el-menu-item index="/pbac/resources">Resources</el-menu-item>
              <el-menu-item index="/pbac/resource-actions">Resource Actions</el-menu-item>
              <el-menu-item index="/pbac/permissions">Permissions</el-menu-item>
              <el-menu-item index="/pbac/permission-groups">Permission Groups</el-menu-item>
              <el-menu-item index="/pbac/user-permission-groups">User Permission Groups</el-menu-item>

              <el-menu-item index="/pbac/result-restrictors">Result Restrictors</el-menu-item>
              <el-menu-item index="/pbac/properties-restrictors">Properties Restrictors</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="/data-access-control">
              <template #title><el-icon><Tools/></el-icon> Data Access Control</template>
              <el-menu-item index="/data-access-control/settings">Settings</el-menu-item>
              <el-menu-item index="/data-access-control/users">Users</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-scrollbar></el-aside
      >
      <el-container>
        <el-header class="admin-header">
          <el-menu
            class="admin-menu"
            mode="horizontal"
            :ellipsis="false"
            :router="true"
            background-color="#333"
            text-color="#fff"
            active-text-color="#fff"
          >
            <div class="flex-grow"></div>
            <el-menu-item
              ><el-icon><Tools /></el-icon
            ></el-menu-item>
            <el-menu-item
              >
              <a :href="`${envStore.ssoUrl}?logout`">
              <el-icon><SwitchButton /></el-icon>
              </a>
            </el-menu-item>
          </el-menu>
        </el-header>
        <el-main class="admin-main">
          <!--<el-scrollbar>-->
            <router-view />
          <!--</el-scrollbar>-->
        </el-main>
      </el-container>
    </el-container>
  </el-config-provider>
</template>

<script setup>
import { onBeforeMount, computed } from "vue";
import { ElConfigProvider } from "element-plus";
import { useAuthStore, useEnvStore } from "@dutypay/store-modules";
import elementLocales from "@dutypay/components-library/src/locales/element-plus";
import {
  User,
  OfficeBuilding,
  Tools,
  SwitchButton,
  Lock, MapLocation, Platform,
} from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
//import {menuItems} from './pages.js';

const authStore = useAuthStore();
const envStore = useEnvStore();
const isAuth = computed(() => {
  return authStore.status === "success";
});
//const companyStore = useCompanyStore();
//const ReminderSelectCompany = defineAsyncComponent(() => import('@dutypay/settings-pages/src/reminder/ReminderSelectCompany'))

const { locale } = useI18n({ useScope: "global" });
const elementLocale = computed(() => {
  return elementLocales[locale.value];
});

async function initApp() {
  envStore.setGlobalLoading();
  await envStore.fetchHosts();
  await authStore.tokenAuth();
  await envStore.getDebug();
  //await companyStore.initCompanyStore()
  envStore.releaseGlobalLoading();
}

onBeforeMount(async () => {
  await initApp();
});
</script>

<script>
import { mapState } from "pinia";
export default {
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<style lang="scss">
$--color-primary: #5392bc;
@import "element-plus/theme-chalk/dark/css-vars.css";
@import "~@dutypay/design-system/src/scss_legacy/index.scss"; // TODO: Legacy scss. Can be removed when the last relicts of element are gone.

.flex-grow {
  flex-grow: 1;
}

.admin-header {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 0;
  background-color: #333;
}

.admin-logo-wrapper {
  display: inline-flex;
  justify-content: start;
  align-items: center;
  color: #fff;
  height: 60px;
}

.admin-menu {
  width: 100%;
  border: 0 !important;
}

.admin-main {
  width: 100%;
  margin: 0 auto;
  padding: 3rem;
  color: #fff;
  background-color: #000;
}

.admin-aside {
  background-color: #333;
  min-height: 100vh;
  border: 0 !important;
}

.admin-aside-menu {
  border: 0 !important;
}

.admin-aside-menu .el-menu-item,
.admin-aside-menu .el-menu-item-group {
  background-color: #222;
}

.el-menu--horizontal.el-menu {
  border: 0 !important;
}
</style>

<i18n>
{}
</i18n>
