<script setup>
import {to} from "await-to-js";
import {onMounted, reactive, ref} from "vue";
import {cloneDeep, keyBy, merge, isEmpty, uniqBy, set, get} from "lodash";
import {interceptor} from "@dutypay/utilities";
import {useEnvStore} from "@dutypay/store-modules";
import {ElNotification} from "element-plus";

const loading = ref(false);
const users = ref([]);
const companies = ref([]);
const companySelectedID = ref('')
const usersDataAccessControlResult = ref([])
const queriedUserCount = ref(0);
const totalUserCount = ref(0);
const userSelectedID = ref('')
const relationSelectedID = ref('')

const envStore = useEnvStore();
const selectedClassName = ref('')
const classNameDetailResult = ref({})
const levels = ref([])
const levelsPropertyRelationKeyList = ref([])
const filteredLevels = ref([])
const isClassNameDetailsDialogVisible = ref(false);
const parameters = reactive({
  pageSize: 100,
  pageNumber: '1',
});

async function getLevelsByFilter(paramsBody) {

  // Reset levels and their filters
  levels.value = [];
  filteredLevels.value = [];
  levelsPropertyRelationKeyList.value = [];
  relationSelectedID.value = '';

  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/data-access-control-levels/`, {
        params: paramsBody
      })
  );

  if (err) {
    if (err.response.data.name === "Not Found Error") {
      ElNotification({
        title: "No DAC-Levels found",
        type: "error",
        message: err.message
      });
      loading.value = false;
    } else {
      ElNotification({
        title: "Error on fetching levels to level page",
        type: "error",
        message: err.message
      });
      loading.value = false;
      throw err;
    }
  } else {
    levels.value = res.data.result;
    filteredLevels.value = res.data.result;
    for (let level of levels.value) {
      levelsPropertyRelationKeyList.value.push(level.property.relation.key)
    }
    levelsPropertyRelationKeyList.value = uniqBy(levelsPropertyRelationKeyList.value, "value");
  }
}

async function handleUpdateUsersDataAccessControlLevel(data) {
  loading.value = true;

  let err, res;
  [err, res] = await to(
      interceptor.patch(`${envStore.apiUrls.dutypay}/api/v0/data-access-control-levels/${data.hs.data.access.control.level.id}`, data)
  );

  if (err) {
    ElNotification({
      title: "Error to update data access control level",
      type: "error",
      message: err.message
    });
    loading.value = false;
    throw err;
  }

  ElNotification({
    title: res.data.name,
    type: "success",
    message: res.data.message
  });
  loading.value = false;
}

async function updateClassNameDetailsForm(data) {
  let cloneDataDetail = cloneDeep(data)
  delete cloneDataDetail['children']
  classNameDetailResult.value = cloneDataDetail
  selectedClassName.value = `Details about class name ${cloneDataDetail['class']['name']}`

  let paramBody = {
    "hs.user.id": userSelectedID.value,
    "class.name": cloneDataDetail['class']['name']
  }

  try {
    await getLevelsByFilter(paramBody);
  } catch (err) {
    ElNotification({
      title: "Error to get levels by filter",
      type: "error",
      message: err.message
    });
    throw err
  }
  isClassNameDetailsDialogVisible.value = true;
}

async function refreshUserDataByJob() {
  loading.value = true;

  let err, res;
  [err, res] = await to(
      interceptor.post(`${envStore.apiUrls.dutypay}/api/v0/data-access-control-models/refresh`,
          {
            "users": {
              "tableName": `${envStore.appEnv}_users`,
              "propertyName": "hs.user.id",
              "propertyValue": userSelectedID.value
            },
            "companies": {
              "tableName": `${envStore.appEnv}_companies`,
              "propertyName": "hs.company.id",
              "propertyValue": companySelectedID.value
            }
          })
  );
  if (err) {
    ElNotification({
      title: "Error to refresh data access model",
      type: "error",
      message: err.message
    });
    loading.value = false;
    throw err;
  }

  ElNotification({
    title: res.data.name,
    type: "success",
    message: res.data.message
  });
  loading.value = false;
}

async function getUserDataAccessControl() {
  loading.value = true;

  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/data-access-control-models/${userSelectedID.value}-${companySelectedID.value}`, {})
  );

  if (err) {
    ElNotification({
      title: "Error to fetch data access model",
      type: "error",
      message: err.message
    });
    usersDataAccessControlResult.value = []
    loading.value = false;
    throw err;
  } else {
    usersDataAccessControlResult.value = res.data.result;
  }
  loading.value = false;
}

function handleResetDACLevels(row, level) {
  switch (level) {
    case 'isAvailable':
      row.isReadable = false;
      row.isEditable = false;
      row.isRedacted = false;
      break;
    case 'isReadable':
      row.isEditable = false;
      row.isRedacted = false;
      break;
  }
}

async function handleUserIDSelected() {
  usersDataAccessControlResult.value = [];
  if (!(isEmpty(userSelectedID.value))) {
    companies.value = [];
    await getM2MUserCompany()
  }
  companySelectedID.value = '';
}

async function handleCompanyIDSelected() {
  usersDataAccessControlResult.value = [];

  if (!(isEmpty(userSelectedID.value)) && !(isEmpty(companySelectedID.value))) {
    await getUserDataAccessControl();
  }
}

function handleRelationIDSelected() {

  loading.value = true;

  if (isEmpty(relationSelectedID.value) === true) {
    filteredLevels.value = levels.value;
  } else {
    filteredLevels.value = levels.value.filter(function (level) {
      return level.property.relation.key.value === relationSelectedID.value;
    });
  }
  loading.value = false;
}

async function getUsers() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/users-list`, {
        params: {
          pageNumber: parameters.pageNumber,
          pageSize: parameters.pageSize,
        },
      })
  );
  if (err) {
    loading.value = false;
    throw err;
  }

  users.value = merge(keyBy(users.value, 'hs.user.id'), keyBy(res.data.usersList, 'hs.user.id'));

  //queriedUserCount.value = res.data.queriedUserCount;
  totalUserCount.value = res.data.totalUserCount;

  queriedUserCount.value += res.data.usersList.length
  if (queriedUserCount.value < totalUserCount.value) {
    parameters.pageNumber = (parseInt(parameters.pageNumber, 10) + 1).toString();
    await getUsers();
  }

  loading.value = false;
}

async function getM2MUserCompany() {
  loading.value = true;

  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/users/${userSelectedID.value}/linked-companies`, {})
  );
  if (err) {
    loading.value = false;
    throw err;
  }


  let updatedCompanies = [];
  for (let company of res.data) {
    if (isEmpty(get(company, 'name.legal'))) {
      company = set(company, 'name.legal', 'Missing legal name for ' + get(company, 'hs.company.id'));
    }
    updatedCompanies.push(company);
  }
  companies.value = updatedCompanies;

  if (isEmpty(companies.value)) {
    ElNotification({
      title: "No Linked Companies found",
      type: "error",
      message: "Please assign this user at least to one company!"
    });
  }

  loading.value = false;

}

/*
async function getCompanies(searchString) {

  loading.value = true;
  console.log("searchString", searchString)

  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/my/companies/all`, {
        params: {
          search: searchString,
        },
      })
  );
  if (err) {
    loading.value = false;
    throw err;
  }
  console.log("await getUsers();", res.data.result.companies);

  companies.value = res.data.result.companies;

  loading.value = false;
}
*/

onMounted(async () => {
  await getUsers();
});
</script>

<script>
export default {
  name: "DataAccessControlUsersPage",
  components: {},
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="16" :xl="16" :lg="16" :sm="32" :xs="32">
        <h1>User Data Access Control Overview
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8" :xl="8" :lg="8" :sm="16" :xs="16"
      >
        <el-form
            label-position="left"
        >
          <el-form-item label="Users">
            <el-select v-model="userSelectedID" filterable clearable placeholder="Select"
                       @change="handleUserIDSelected">
              <el-option
                  v-for="item in users"
                  :key="item.hs.user.id"
                  :label="item.primary.email.address"
                  :value="item.hs.user.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>

      <el-col :span="8" :xl="8" :lg="8" :sm="16" :xs="16"
      >
        <el-form
            label-position="right"
        >

          <el-form-item label="Linked Companies">
            <el-select v-model="companySelectedID" :disabled="userSelectedID.length === 0" filterable clearable
                       placeholder="Select"
                       @change="handleCompanyIDSelected">
              <el-option
                  v-for="item in companies"
                  :key="item.hs.company.id"
                  :label="item.name.legal"
                  :value="item.hs.company.id"
              />
            </el-select>
          </el-form-item>
          <!--<el-form-item label="Companies">
            <el-select
                v-model="companySelectedID"
                filterable
                reserve-keyword
                remote
                clearable
                placeholder="Select"
                :remote-method="getCompanies">
              <el-option
                  v-for="item in companies"
                  :key="item['hs.company.id']"
                  :label="item.name.legal"
                  :value="item['hs.company.id']"
              />
            </el-select>
          </el-form-item>-->
        </el-form>
      </el-col>

      <el-col :span="8" :xl="8" :lg="8" :sm="16" :xs="16">
        <el-button v-if="userSelectedID && companySelectedID" type="primary" @click="refreshUserDataByJob"
                   style="float:left">Refresh Data
        </el-button>
        <el-button v-if="userSelectedID && companySelectedID" type="primary" @click="getUserDataAccessControl"
                   style="float:left">Fetch
          Current Data
        </el-button>
      </el-col>
    </el-row>
    <el-row v-if="usersDataAccessControlResult" :gutter="20">
      <el-col>
        <el-table
            :data="usersDataAccessControlResult"
            stripe style="width: 100%; height: 75vh;"
            row-key="class.name"
            border
            default-expand-all>
          <el-table-column
              prop="class.name"
              label="Class Name"
              :minWidth="500">
          </el-table-column>
          <el-table-column label="Actions" align="center">
            <template v-slot="usersDataAccessControlResult" v-if="usersDataAccessControlResult">
              <el-button type="info"
                         @click="updateClassNameDetailsForm(usersDataAccessControlResult.row);isClassNameDetailsDialogVisible = true;">
                Details
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
        v-model="isClassNameDetailsDialogVisible"
        :title="selectedClassName"
        width="100%"
        align-center
        draggable>
      <el-form label-position="left">
        <el-form-item label="Class Name" style="width:30%; float:left; margin: 10px;">
          <el-input v-model="classNameDetailResult.class.name" readonly></el-input>
        </el-form-item>

        <el-form-item label="User-ID" style="width:30%; float:left; margin: 10px;">
          <el-input v-model="userSelectedID" readonly></el-input>
        </el-form-item>

        <el-form-item label="Relation Key ID" style="width:30%; float:left; margin: 10px;">
          <el-select v-model="relationSelectedID" filterable clearable placeholder="Select"
                     @change="handleRelationIDSelected">
            <el-option
                v-for="item in levelsPropertyRelationKeyList"
                :key="item.value"
                :label="item.name + ' | ' + item.value"
                :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item style="width:100%;">
          <label>Property Level Actions</label>
          <el-table
              :data="filteredLevels"
              style="width: 100%;"
              border>
            <el-table-column
                prop="property.relation.key.name"
                label="Property Relation Key Name"
                sortable
            >
            </el-table-column>

            <el-table-column
                prop="property.relation.key.value"
                label="Property Relation Key Value"
                sortable
            >
            </el-table-column>

            <el-table-column
                prop="property.name"
                label="Property Name"
                sortable
            >
            </el-table-column>

            <el-table-column
                label="IsAvailable" :width="100" align="center">
              <template v-slot="scope">
                <el-checkbox @click="handleResetDACLevels(scope.row, 'isAvailable')" :checked="scope.row.isAvailable"
                             v-model="scope.row.isAvailable"></el-checkbox>
              </template>
            </el-table-column>

            <el-table-column
                label="IsReadable" :width="100" align="center">
              <template v-slot="scope">
                <el-checkbox @click="handleResetDACLevels(scope.row, 'isReadable')" :checked="scope.row.isReadable"
                             v-model="scope.row.isReadable" :disabled="scope.row.isAvailable===false"></el-checkbox>
              </template>
            </el-table-column>

            <el-table-column
                label="IsEditable" :width="100" align="center">
              <template v-slot="scope">
                <el-checkbox :checked="scope.row.isEditable" v-model="scope.row.isEditable"
                             :disabled="scope.row.isAvailable===false || scope.row.isReadable===false"></el-checkbox>
              </template>
            </el-table-column>

            <el-table-column
                label="IsRedacted" :width="100" align="center">
              <template v-slot="scope">
                <el-checkbox :checked="scope.row.isRedacted" v-model="scope.row.isRedacted"
                             :disabled="scope.row.isAvailable===false || scope.row.isReadable===false"></el-checkbox>
              </template>
            </el-table-column>

            <el-table-column label="Actions" align="center" :width="100">
              <template v-slot="scope">
                <el-button type="warning" @click="handleUpdateUsersDataAccessControlLevel(scope.row);">
                  Save
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isClassNameDetailsDialogVisible = false;">
            Close
          </el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>


<i18n>
{
  "en-US": {
  }
}
</i18n>
