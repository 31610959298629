<script setup>
import {to} from "await-to-js";
import {computed, onMounted, reactive, ref} from "vue";
import {cloneDeep} from "lodash";
import {useRoute} from 'vue-router'
import {interceptor} from "@dutypay/utilities";
import {useEnvStore} from "@dutypay/store-modules";
import {ElNotification} from "element-plus";

const loading = ref(false);
const settings = ref([]);
const envStore = useEnvStore();
const route = useRoute();
const selectedEditedId = ref('')
const isManageSettingDialogVisible = ref(false);

const manageSettingFormBlank = {

  "isAggregated": false,
  "class": {
    "name": ""
  },
  "relation": {
    "key": {
      "property": {
        "name": {
          "list": []
        }
      }
    }
  },
  "filter": {
    "data": {
      "restriction": {
        "list": []
      }
    }
  }

};
const manageSettingForm = ref({
  "isAggregated": false,
  "class": {
    "name": ""
  },
  "relation": {
    "key": {
      "property": {
        "name": {
          "list": []
        }
      }
    }
  },
  "filter": {
    "data": {
      "restriction": {
        "list": []
      }
    }
  }
});

const pageSizeOptions = ref([
  {label: '10', value: '10'},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  pageNumber: "1"
});

const queriedSettingCount = ref(0);
const totalSettingCount = ref(0);

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getSettings(route.params.id)
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber, 10) > 1;
});

async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) - 1).toString();
  await getSettings(route.params.id);
}

const hasNextPage = computed(() => {
  return (
      queriedSettingCount.value < totalSettingCount.value)
});

async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) + 1).toString();
  await getSettings(route.params.id);
}

async function getSettings() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/data-access-control-settings/`, {
        params: {
          pageSize: parameters.pageSize,
          pageNumber: parameters.pageNumber,

        }
      })
  );

  if (err) {
    if (err.response.data.name === "Not Found Error") {
      settings.value = [];
    } else {
      ElNotification({
        title: "Error on fetching settings to setting page",
        type: "error",
        message: err.message
      });
      loading.value = false;
      console.log("err", err)
      throw err;
    }
  } else {
    //console.log("res.data.result", res.data.result)
    //throw new Error('BREAKPOINT');

    settings.value = res.data.result.dataAcessControllSettingList;
    queriedSettingCount.value = res.data.result.sqlQueriedDataAccessControlSettingCurrentCount;
    totalSettingCount.value = res.data.result.sqlQueriedDataAccessControlSettingTotalCount;
  }
  loading.value = false;
}

async function updateSettingForm(data){
  loading.value = true;

  selectedEditedId.value = data.hs.data.access.control.setting.id
  manageSettingForm.value = data;
  loading.value= false;
  await getSettings();
}

async function updateSetting(id) {

  try {
    await
        interceptor.patch(
            `${envStore.apiUrls.dutypay}/api/v0/data-access-control-settings/${id}`,
            manageSettingForm.value
        )
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on updating setting to setting page",
      type: "error",
      message: err.message
    });
    console.log("err", err)
    isManageSettingDialogVisible.value = false;
    return;
  }
  ElNotification({
    title: "Setting successfully updated to setting page.",
    type: "success",
  });
  loading.value = false;
  manageSettingForm.value = cloneDeep(manageSettingFormBlank);
  selectedEditedId.value='';
  isManageSettingDialogVisible.value = false;
  await getSettings(route.params.id);
}


async function addSetting() {
  //delete manageSettingForm.value.hs.frontend.page.grant

  try {
    await
        interceptor.post(
            `${envStore.apiUrls.dutypay}/api/v0/data-access-control-settings`,
            manageSettingForm.value
        )
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on adding setting to setting page",
      type: "error",
      message: err.message
    });
    console.log("err", err)
    isManageSettingDialogVisible.value = false;
    return;
  }
  ElNotification({
    title: "Setting successfully added to setting page.",
    type: "success",
  });
  loading.value = false;
  manageSettingForm.value = cloneDeep(manageSettingFormBlank);
  isManageSettingDialogVisible.value = false;
  await getSettings(route.params.id);
}

async function deleteSetting(hsSettingId) {
  console.log(hsSettingId)
  try {
    await
        interceptor.delete(
            `${envStore.apiUrls.dutypay}/api/v0/data-access-control-settings/${hsSettingId}`,
            {}
        )
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on deleting Setting",
      type: "error",
      message: err.message
    });
    console.log("err", err)
    isManageSettingDialogVisible.value = false;
    return;
  }
  ElNotification({
    title: "Setting successfully deleted.",
    type: "success",
  });
  loading.value = false;
  await getSettings();
}

onMounted(async () => {
  //await getGrants();
  //await getSettingPage(route.params.id);
  await getSettings();
  //manageSettingForm.value.hs.frontend.page.id = route.params.id
});

</script>

<script>
import PaginationNavigationComponent from '../../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "SettingsPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div v-if="settings">
    <el-row :gutter="20">
      <el-col :span="16" :xl="16" :lg="16" :sm="32" :xs="32">
        <h1>Data Access Control Settings Overview&nbsp;
          <el-button type="success" @click="isManageSettingDialogVisible = true">
            Add
          </el-button>
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
          class="pagesize"
          style="display: flex; justify-content: flex-end; float: right;"
      >
        <pagination-filter-options-radio-component
            label="Settings per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col
      >
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="settings" stripe style="width: 100%">
          <el-table-column prop="hs.data.access.control.setting.id" label="Setting ID"/>
          <el-table-column prop="class.name" label="Class"/>
          <el-table-column prop="isAggregated" label="IsAggregated"/>
          <el-table-column prop="relation.key.property.name.list" label="Relation Key Property Name List"/>
          <el-table-column prop="filter.data.restriction.list" label="Filter Data Restriction List"/>
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="updateSettingForm(scope.row);isManageSettingDialogVisible = true;"
              >Edit
              </el-button>
              <el-button type="danger" @click="deleteSetting(scope.row.hs.data.access.control.setting.id)"
              >Delete
              </el-button
              >

            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
        v-model="isManageSettingDialogVisible"
        title="Setting to SettingPage"
        width="600px"
        align-center
        draggable
    >
      <el-form
          :model="manageSettingForm"
          label-position="left"
          label-width="200px"
          style="max-width: 600px"
      >

        <el-form-item label="Class Name">
          <el-input v-model="manageSettingForm.class.name"></el-input>
        </el-form-item>

        <el-form-item label="Relation Key Property List">

          <el-select
              v-model="manageSettingForm.relation.key.property.name.list"
              multiple
              filterable
              allow-create
              placeholder="Define Relation Keys for this class">
            <el-option
                v-for="item in manageSettingForm.relation.key.property.name.list"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="IsAggregated">
          <el-checkbox v-model="manageSettingForm.isAggregated">Should the Result about IDs be aggregated?</el-checkbox>
        </el-form-item>

        <el-form-item label="Filter Data Restriction List">

          <el-select
              v-model="manageSettingForm.filter.data.restriction.list"
              multiple
              filterable
              allow-create
              placeholder="Define Restrictions which data should be checked">
            <el-option
                v-for="item in manageSettingForm.filter.data.restriction.list"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>


      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="manageSettingForm= cloneDeep(manageSettingFormBlank); selectedEditedId='';isManageSettingDialogVisible = false;"
          >Cancel</el-button
          >
          <el-button v-if="selectedEditedId" type="primary" @click="updateSetting(manageSettingForm.hs.data.access.control.setting.id)">
            Update to SettingPage
          </el-button>
          <el-button v-else type="primary" @click="addSetting">
            Add Setting to SettingPage
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>