import {createRouter, createWebHistory} from 'vue-router'

// Pages
import {pages} from './pages.js';

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0 }
        }
    },
    routes: [
        // ----------------
        //  PAGES
        // ----------------
        ...pages,
    ]
});
