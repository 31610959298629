<script setup>
import { to } from "await-to-js";
import { ref, reactive, onMounted, computed } from "vue";
//import { get } from "lodash";
import { interceptor } from "@dutypay/utilities";
import { useEnvStore } from "@dutypay/store-modules";
import {ElNotification} from "element-plus";
const loading = ref(false);
const companies = ref([]);
const queriedCompaniesCount = ref(0);
const totalCompaniesCount = ref(0);
const envStore = useEnvStore();

const pageSizeOptions = ref([
  {label: '10', value: "10"},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  search: "",
  pageNumber: "1",
});

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getCompanies()
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber,10) > 1;
});
async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber,10) - 1).toString();
  await getCompanies();
}

const hasNextPage = computed(() => {
  return (
    parameters.pageNumber <
    Math.ceil(parseInt(queriedCompaniesCount.value,10) / parseInt(parameters.pageSize,10))
  );
});
async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber,10) + 1).toString();
  await getCompanies();
}

async function resetConfirmationsFromComany(companyID){
  loading.value = true;
  try {
    await
        interceptor.patch(
            `${envStore.apiUrls.contractshub}/api/v1/contract-data-confirmations/${companyID}`,
        )
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on resetting confirmations",
      type: "error",
      message: err.message
    });
    console.log("err", err)
    return;
  }
  ElNotification({
    title: "Confirmations successfully reset.",
    type: "success",
  });
  loading.value = false;
}

async function generateContractData(){
  loading.value = true;
  try {
    await
        interceptor.post(
            `${envStore.apiUrls.contractshub}/api/v1/contract-data/generate-all`,
        )
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on generate contract data",
      type: "error",
      message: err.message
    });
    console.log("err", err)
    return;
  }
  ElNotification({
    title: "Contract data successfully created for all companies.",
    type: "success",
  });
  loading.value = false;
}

async function getCompanies() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
    interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/companies-list`, {
      params: {
        search: parameters.search,
        pageNumber: parameters.pageNumber,
        pageSize: parameters.pageSize,
      },
    })
  );
  if (err) {
    loading.value = false;
    throw err;
  }
  companies.value = [];
  companies.value = res.data.result.companiesList;
  queriedCompaniesCount.value = res.data.result.queriedCompanyCount;
  totalCompaniesCount.value = res.data.result.totalCompanyCount;
  loading.value = false;
}
onMounted(async () => {
  await getCompanies();
});
</script>

<script>
import PaginationNavigationComponent from '../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "CompaniesPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Companies</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="10" :xl="10" :lg="10" :sm="24" :xs="24" class="searchform">
        <el-form-item style="flex-grow: 1">
          <el-input
            v-model="parameters.search"
            @click="getCompanies()"
            @change="parameters.pageNumber = 1"
            placeholder="Search for sp.id, hs.id, customer number"
            :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getCompanies"> Search </el-button>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="info" @click="generateContractData"> Generate Contract Data for all</el-button>
        </el-form-item>
      </el-col>
      <el-col
        :span="14"
        :xl="14"
        :lg="14"
        :sm="24"
        :xs="24"
        class="pagesize"
        style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="Companies per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="companies" stripe style="width: 100%">
          <el-table-column prop="name.legal" label="Legal name" />
          <el-table-column prop="customer.number" label="Customer number" />
          <el-table-column prop="hs.company.id" label="HubSystem ID" />
          <el-table-column prop="sp.company.id" label="SharePoint ID" />
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="resetConfirmationsFromComany(scope.row.hs.company.id)"
              >Reset Confirmations</el-button
              >
            </template>
          </el-table-column>

        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
