import { to } from 'await-to-js';
import { createI18n } from 'vue-i18n'
import { useJurisdictionSwitch } from "@dutypay/components-library/src/composables/jurisdictionSwitch";
import '@dutypay/components-library/src/locales/numeralLocales'

const {
  loadJurisdiction,
  setDayJSLocale,
  setNumeralLocale,
  setDocumentLanguage,
  setJurisdictionInLocalStorage,
} = useJurisdictionSwitch();

const jurisdiction = await loadJurisdiction();
setDayJSLocale(jurisdiction);
setNumeralLocale(jurisdiction);
setDocumentLanguage(jurisdiction);
setJurisdictionInLocalStorage(jurisdiction);
const locale = jurisdiction.i18n;

async function loadLocaleMessages() {
    let messages = {};
    let translationValues, error;
    [error, translationValues] = await to(import('/src/locales/' + locale + '/translationValues.json'));
    if (error) {
        throw error;
    }
    let elementLocale;
    [error, elementLocale] = await to(import('@dutypay/components-library/src/locales/element-plus/' + locale + '.js'));
    if (error) {
        throw error;
    }
    messages[locale] = Object.assign(elementLocale.default, translationValues.default);
    return messages;
}

export const i18n = createI18n({
    globalInjection: true,
    useScope: "global",
    legacy: false,
    locale: locale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
    messages: await loadLocaleMessages(),
    fallbackWarn: false,
    silentTranslationWarn: true,
    silentFallbackWarn: true
});

