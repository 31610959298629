<script setup>
import {to} from "await-to-js";
import {ref, reactive, onMounted, computed} from "vue";
import {cloneDeep, get} from "lodash";
import {interceptor} from "@dutypay/utilities";
import {useEnvStore} from "@dutypay/store-modules";
import {ElNotification} from "element-plus";

const loading = ref(false);
let isAddUserPermissionGroupDialogVisible = ref(false);

const addUserPermissionGroupFormBlank =
    {
      hs: {
        user: {
          permission: {
            group: {
              id: ""
            }
          },
          id: ""
        },
        permission: {
          group: {
            id: ""
          }
        },
        company: {
          id: ""
        }
      },

    };

const addUserPermissionGroupForm = ref({
  hs: {
    user: {
      permission: {
        group: {
          id: ""
        }
      },
      id: ""
    },
    permission: {
      group: {
        id: ""
      }
    },
    company: {
      id: ""
    }
  }
});

const isEditUserPermissionGroupDialogVisible = ref(false);
const editUserPermissionGroupFormBlank = {
  hs: {
    user: {
      permission: {
        group: {
          id: ""
        }
      },
      id: ""
    },
    permission: {
      group: {
        id: ""
      }
    },
    company: {
      id: ""
    }
  }
};
const editUserPermissionGroupForm = ref({
  hs: {
    user: {
      permission: {
        group: {
          id: ""
        }
      },
      id: ""
    },
    permission: {
      group: {
        id: ""
      }
    },
    company: {
      id: ""
    }
  }
});


const userPermissionGroups = ref([]);
const permissionGroups = ref([]);
const users = ref([]);
const companies = ref([]);
//const filteredCompanies = ref([]);


const queriedUserPermissionGroupCount = ref(0);
const totalUserPermissionGroupCount = ref(0);
const envStore = useEnvStore();

const pageSizeOptions = ref([
  {label: '10', value: "10"},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  search: "",
  pageNumber: "1",
});

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getUserPermissionGroups()
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber, 10) > 1;
});

async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) - 1).toString();
  await getUserPermissionGroups();
}

const hasNextPage = computed(() => {
  return (queriedUserPermissionGroupCount.value < totalUserPermissionGroupCount.value);
});

async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) + 1).toString();
  await getUserPermissionGroups();
}

async function getCompaniesByUserID(userID) {

  if (userID) {
    loading.value = true;
    companies.value = []
    //addUserPermissionGroupForm.value = set(addUserPermissionGroupForm.value, 'hs.company.id', '');
    //editUserPermissionGroupForm.value = set(editUserPermissionGroupForm.value, 'hs.company.id', '');
    let err, res;
    [err, res] = await to(
        interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/users/${userID}/linked-companies`, {})
    );
    if (err) {
      loading.value = false;
      return;
    }
    companies.value = [];
    companies.value = res.data.result;
    //filteredCompanies.value = companies.value
    console.log("companies", companies.value)
    loading.value = false;
  }
}

async function getUsers() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/users`, {})
  );
  if (err) {
    loading.value = false;
    return;
  }
  users.value = [];
  users.value = res.data.result;
  loading.value = false;
}

async function getPermissionGroups() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/permission-groups`)
  );
  if (err) {
    loading.value = false;
    return;
  }
  permissionGroups.value = [];
  permissionGroups.value = res.data.result;
  loading.value = false;
}


async function getUserPermissionGroups() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/user-permission-groups`, {
        params: {
          search: parameters.search,
          pageNumber: parameters.pageNumber,
          pageSize: parameters.pageSize,
        },
      })
  );
  if (err) {
    loading.value = false;
    return;
  }
  userPermissionGroups.value = [];
  userPermissionGroups.value = res.data.result.userPermissionGroupList;
  queriedUserPermissionGroupCount.value = res.data.result.sqlQueriedUserPermissionGroupCurrentCount;
  totalUserPermissionGroupCount.value = res.data.result.sqlQueriedUserPermissionGroupTotalCount;
  loading.value = false;
}

async function getUserPermissionGroup(id) {
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/user-permission-groups/${id}`, {})
  );
  if (err) {
    ElNotification({
      title: "Error on getting user permission group with ID " + id,
      type: "error",
    });
    return;
  }
  return res.data.result;
}

async function addUserPermissionGroup() {
  try {
    console.log("addUserPermissionGroupForm", addUserPermissionGroupForm)
    await (
        interceptor.post(
            `${envStore.apiUrls.dutypay}/api/v0/user-permission-groups`,
            addUserPermissionGroupForm.value
        )
    );
    ElNotification({
      title: "User Permission Group successfully added.",
      type: "success",
    });
    loading.value = false;
    addUserPermissionGroupForm.value = cloneDeep(addUserPermissionGroupFormBlank);
    isAddUserPermissionGroupDialogVisible.value = false;
    await getUserPermissionGroups();
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on adding user permission group",
      type: "error",
    });
    isAddUserPermissionGroupDialogVisible.value = false;
  }
}


async function handleEditUserPermissionGroup(index, permission) {
  let id = permission.hs.user.permission.group.id;
  let err, permissionFromServer;
  [err, permissionFromServer] = await to(getUserPermissionGroup(id));
  if (err) {
    ElNotification({
      title: "Error on getting permission " + id,
      type: "error",
    });
  }
  editUserPermissionGroupForm.value = permissionFromServer;
  isEditUserPermissionGroupDialogVisible.value = true;
}

async function deleteUserPermissionGroup(index, data) {
  try {
    loading.value = true;
    await to(
        interceptor.delete(`${envStore.apiUrls.dutypay}/api/v0/user-permission-groups/${data.hs.user.permission.group.id}`));
    ElNotification({
      title: "UserPermissionGroup successfully deleted.",
      type: "success",
    });
    loading.value = false;
    await getUserPermissionGroups();

  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on deleting permission",
      type: "error",
    });
  }
}

async function editUserPermissionGroup() {
  try {
    await to(
        interceptor.patch(
            `${envStore.apiUrls.dutypay}/api/v0/user-permission-groups/${editUserPermissionGroupForm.value.hs.user.permission.group.id}`,
            editUserPermissionGroupForm.value
        )
    );
    ElNotification({
      title: "User Permission Group successfully edited.",
      type: "success",
    });
    loading.value = false;
    editUserPermissionGroupForm.value = cloneDeep(editUserPermissionGroupFormBlank);
    isEditUserPermissionGroupDialogVisible.value = false;
    await getUserPermissionGroups();

  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on editing permission",
      type: "error",
    });
    isEditUserPermissionGroupDialogVisible.value = false;
  }
}

function getCompanyLabel(company) {
  return get(company, 'name.legal') || get(company, 'hs.company.id', '???')
}

onMounted(async () => {
  await getUsers();
  await getPermissionGroups();
  await getUserPermissionGroups();
});
</script>

<script>
import PaginationNavigationComponent from '../../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "UserPermissionGroupsPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>
          UserPermissionGroups &nbsp;
          <el-button type="success" @click="isAddUserPermissionGroupDialogVisible = true">
            Add
          </el-button>
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!--<el-col :span="8" :xl="8" :lg="8" :sm="24" :xs="24" class="searchform">
        <el-form-item style="flex-grow: 1">
          <el-input
              v-model="parameters.search"
              @click="getUserPermissionGroups()"
              @change="parameters.pageNumber = 1"
              placeholder="Search for hs.id, name"
              :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getUserPermissionGroups"> Search</el-button>
        </el-form-item>
      </el-col>-->
      <el-col
          :span="16"
          :xl="16"
          :lg="16"
          :sm="24"
          :xs="24"
          class="pagesize"
          style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="UserPermissionGroups per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="userPermissionGroups" stripe style="width: 100%">
          <el-table-column prop="hs.user.permission.group.id" label="HubSystem User Permission Group ID"/>
          <el-table-column label="HubSystem Permission Group Title">
            <template #default="scope">
              <div>
                <p>{{ scope.row.description.text }} </p>
                <p style="font-size: smaller; ">
                  ({{ scope.row.hs.permission.group.id}}) </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="HubSystem User Email">
            <template #default="scope">
              <div>
                <p>{{ scope.row.primary.email.address }} </p>
                <p style="font-size: smaller; ">
                  ({{ scope.row.hs.user.id}}) </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="HubSystem Company Name">
            <template #default="scope">
              <div>
                <p>{{ scope.row.legal.name || 'Undefined'}} </p>
                <p style="font-size: smaller; ">
                  ({{ scope.row.hs.company.id || '???'}}) </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="handleEditUserPermissionGroup(scope.$index, scope.row)"
              >Edit
              </el-button>
              <el-button type="danger" @click="deleteUserPermissionGroup(scope.$index, scope.row)"
              >Delete
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
        v-model="isAddUserPermissionGroupDialogVisible"
        title="Add UserPermissionGroup"
        width="1000px"
        align-center
        draggable
    >
      <el-form
          :model="addUserPermissionGroupForm"
          label-position="left"
          label-width="300px"
          style="max-width: 1000px"
      >

        <el-form-item label="HubSystem Permission Group ID">
          <el-select v-model="addUserPermissionGroupForm.hs.permission.group.id" filterable placeholder="Select">
            <el-option
                v-for="item in permissionGroups"
                :key="item.hs.permission.group.id"
                :label="item.description.text"
                :value="item.hs.permission.group.id"
            />
          </el-select>
        </el-form-item>


        <el-form-item label="HubSystem Company ID">
          <el-select v-model="addUserPermissionGroupForm.hs.company.id" placeholder="Select" clearable filterable
                     :disabled="companies.length === 0">
            <el-option
                v-for="item in companies"
                :key="item.hs.company.id"
                :label="getCompanyLabel(item)"
                :value="item.hs.company.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="HubSystem User ID">
          <el-select v-model="addUserPermissionGroupForm.hs.user.id"
                     :onChange="getCompaniesByUserID(addUserPermissionGroupForm.hs.user.id)" filterable
                     placeholder="Select">
            <el-option
                v-for="item in users"
                :key="item.hs.user.id"
                :label="item.primary.email.address"
                :value="item.hs.user.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAddUserPermissionGroupDialogVisible = false"
          >Cancel</el-button
          >
          <el-button type="primary" @click="addUserPermissionGroup">
            Add UserPermissionGroup
          </el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog
        v-model="isEditUserPermissionGroupDialogVisible"
        :title="'Edit UserPermissionGroup \'' + editUserPermissionGroupForm.hs.user.permission.group.id + '\''"
        width="1000px"
        align-center
        draggable
    >
      <el-form
          :model="editUserPermissionGroupForm"
          label-position="left"
          label-width="300px"
          style="max-width: 1000px"
      >

        <el-form-item label="HubSystem Permission Group ID">
          <el-select v-model="editUserPermissionGroupForm.hs.permission.group.id" filterable placeholder="Select">
            <el-option
                v-for="item in permissionGroups"
                :key="item.hs.permission.group.id"
                :label="item.description.text"
                :value="item.hs.permission.group.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="HubSystem Company ID">
          <el-select v-model="editUserPermissionGroupForm.hs.company.id" filterable clearable placeholder="Select"
                     :disabled="companies.length === 0">
            <el-option
                v-for="item in companies"
                :key="item.hs.company.id"
                :label="getCompanyLabel(item)"
                :value="item.hs.company.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="HubSystem User ID">
          <el-select v-model="editUserPermissionGroupForm.hs.user.id" placeholder="Select" filterable
                     :onChange="getCompaniesByUserID(editUserPermissionGroupForm.hs.user.id)">
            <el-option
                v-for="item in users"
                :key="item.hs.user.id"
                :label="item.primary.email.address"
                :value="item.hs.user.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isEditUserPermissionGroupDialogVisible = false"
          >Cancel</el-button
          >
          <el-button type="primary" @click="editUserPermissionGroup">
            Edit UserPermissionGroup
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
{
  "en-US": {
  }
}
</i18n>
