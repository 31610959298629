<script setup>
import { to } from "await-to-js";
import { ref, reactive, onMounted, computed } from "vue";
import { cloneDeep } from "lodash";
import { interceptor } from "@dutypay/utilities";
import { useEnvStore } from "@dutypay/store-modules";
import { ElNotification } from "element-plus";
const loading = ref(false);
const isAddResourceDialogVisible = ref(false);

const addResourceFormBlank = {
  name: "",
  description: {
    text: "",
    url: "",
  },
  display: {
    property: {
      name: ""
    }
  },
};
const addResourceForm = ref({
  name: "",
  description: {
    text: "",
    url: "",
  },
  display: {
    property: {
      name: ""
    }
  },
});

const isEditResourceDialogVisible =  ref(false);
const editResourceFormBlank = {
  hs: {
    id: "",
    resource: {
      id: ""
    }
  },
  name: "",
  description: {
    text: "",
    url: "",
  },
  display: {
    property: {
      name: ""
    }
  },
};

const editResourceForm = ref({
  hs: {
    id: "",
    resource: {
      id: ""
    }
  },
  name: "",
  description: {
    text: "",
    url: "",
  },
  display: {
    property: {
      name: ""
    }
  }
});

const resources = ref([]);
const queriedResourceCount = ref(0);
const totalResourceCount = ref(0);
const envStore = useEnvStore();
const resourceProperties = ref([]);

const pageSizeOptions = ref([
  {label: '10', value: "10"},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  search: "",
  pageNumber: "1",
});

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getResources()
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber,10) > 1;
});
async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber,10) - 1).toString();
  await getResources();
}

const hasNextPage = computed(() => {
  return (
      queriedResourceCount.value < totalResourceCount.value)
});
async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber,10) + 1).toString();
  await getResources();
}

async function getResources() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
    interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/resources`, {
      params: {
        search: parameters.search,
        pageNumber: parameters.pageNumber,
        pageSize: parameters.pageSize,
      },
    })
  );
  if (err) {
    loading.value = false;
    return;
  }
  resources.value = [];
  resources.value = res.data.result.resourceList;
  queriedResourceCount.value = res.data.result.sqlQueriedResourceCurrentCount;
  totalResourceCount.value = res.data.result.sqlQueriedResourceTotalCount;
  loading.value = false;
}

async function getResource(id) {
  let err, res;
  [err, res] = await to(
    interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/resources/${id}`, {})
  );
  if (err) {
    ElNotification({
      title: "Error on getting resource with ID " + id,
      type: "error",
    });
    return;
  }
  return res.data.result;
}

async function getProperties(resource) {
  let err, res;
  [err, res] = await to(
    interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/resource-properties`, {
      params: {
        resource: resource
      }
    })
  );
  if (err) {
    ElNotification({
      title: "Error on getting properties for resource",
      type: "error",
    });
    return;
  }
  return resourceProperties.value = res.data.result;
}

async function addResource() {
  let err, res;
  [err, res] = await to(
    interceptor.post(
      `${envStore.apiUrls.dutypay}/api/v0/resources`,
      addResourceForm.value
    )
  );
  if (err) {
    loading.value = false;
    ElNotification({
      title: "Error on adding resource",
      type: "error",
    });
    isAddResourceDialogVisible.value = false;
    return;
  }
  ElNotification({
    title: "Resource successfully added.",
    type: "success",
  });
  loading.value = false;
  addResourceForm.value = cloneDeep(addResourceFormBlank);
  isAddResourceDialogVisible.value = false;
  await getResources();
}

async function handleDeleteResource(index, resource){
  loading.value = true;
  let id = resource.hs.resource.id;
  try {
    await
        interceptor.delete(
            `${envStore.apiUrls.dutypay}/api/v0/resources/${id}`,
            {}
        )
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on deleting Ressource",
      type: "error",
    });
    return;
  }
  ElNotification({
    title: "Ressource successfully deleted.",
    type: "success",
  });
  loading.value = false;
  resourceProperties.value = [];
  await getResources();
}

async function handleEditResource(index, resource){
  await getProperties(resource.name);
  let id = resource.hs.resource.id;
  let err,resourceFromServer;
  [err, resourceFromServer] = await to(getResource(id));
  if(err){
    ElNotification({
      title: "Error on getting resource " + id,
      type: "error",
    });
  }
  editResourceForm.value = resourceFromServer;
  isEditResourceDialogVisible.value = true;
}

async function editResource() {
  console.log(editResourceForm.value)
  let err, res;
  [err, res] = await to(
    interceptor.patch(
      `${envStore.apiUrls.dutypay}/api/v0/resources/${editResourceForm.value.hs.resource.id}`,
      editResourceForm.value
    )
  );
  if (err) {
    loading.value = false;
    ElNotification({
      title: "Error on editing resource",
      type: "error",
    });
    isEditResourceDialogVisible.value = false;
    return;
  }
  ElNotification({
    title: "Resource successfully edited.",
    type: "success",
  });
  loading.value = false;
  editResourceForm.value = cloneDeep(editResourceFormBlank);
  isEditResourceDialogVisible.value = false;
  await getResources();
}

onMounted(async () => {
  await getResources();
});
</script>

<script>
import PaginationNavigationComponent from '../../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "ResourcesPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>
          Resources &nbsp;
          <el-button type="success" @click="isAddResourceDialogVisible = true">
            Add
          </el-button>
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8" :xl="8" :lg="8" :sm="24" :xs="24" class="searchform">
        <el-form-item style="flex-grow: 1">
          <el-input
            v-model="parameters.search"
            @click="getResources()"
            @change="parameters.pageNumber = 1"
            placeholder="Search for hs.id, name"
            :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getResources"> Search </el-button>
        </el-form-item>
      </el-col>
      <el-col
          :span="16"
          :xl="16"
          :lg="16"
          :sm="24"
          :xs="24"
        class="pagesize"
        style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="Resources per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="resources" stripe style="width: 100%">
          <el-table-column prop="name" label="Resource Name" />
          <el-table-column prop="hs.resource.id" label="HubSystem ID" />
          <el-table-column prop="description.text" label="Description" />
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="handleEditResource(scope.$index, scope.row)"
                >Edit</el-button
              >
              <el-button type="danger" @click="handleDeleteResource(scope.$index, scope.row)"
              >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
      v-model="isAddResourceDialogVisible"
      title="Add Resources"
      width="600px"
      align-center
      draggable
    >
      <el-form
        :model="addResourceForm"
        label-position="left"
        label-width="150px"
        style="max-width: 600px"
      >
        <el-form-item label="Resource Name">
          <el-input v-model="addResourceForm.name" @change="getProperties"/>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="addResourceForm.description.text" />
        </el-form-item>
        <el-form-item label="Description URL">
          <el-input v-model="addResourceForm.description.url" />
        </el-form-item>
        <el-form-item label="Display Property">
          <el-select v-model="addResourceForm.display.property.name" clearable placeholder="Select">
            <el-option
              v-for="item in resourceProperties"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAddResourceDialogVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="addResource">
            Add Resource
          </el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog
      v-model="isEditResourceDialogVisible"
      :title="'Edit Resource \'' + editResourceForm.name + '\''"
      width="600px"
      align-center
      draggable
    >
      <el-form
        :model="editResourceForm"
        label-position="left"
        label-width="150px"
        style="max-width: 600px"
      >
        <el-form-item label="Resource Name">
          <el-input v-model="editResourceForm.name" disabled/>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="editResourceForm.description.text" />
        </el-form-item>
        <el-form-item label="Description URL">
          <el-input v-model="editResourceForm.description.url" />
        </el-form-item>
        <el-form-item label="Display Property">
          <el-select v-model="editResourceForm.display.property.name" clearable placeholder="Select">
            <el-option
              v-for="item in resourceProperties"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <!--<el-form-item label="Deleted?">
          <el-checkbox v-model="editResourceForm.deleted" :checked="!!editResourceForm.deleted">Deleted?</el-checkbox>
        </el-form-item>-->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isEditResourceDialogVisible = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="editResource">
            Edit Resource
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
