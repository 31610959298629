<script setup>
import {to} from "await-to-js";
import {ref, reactive, onMounted, computed} from "vue";
import {cloneDeep} from "lodash";
import {interceptor} from "@dutypay/utilities";
import {useEnvStore} from "@dutypay/store-modules";
import {ElNotification} from "element-plus";

const loading = ref(false);
const isAddResourceActionDialogVisible = ref(false);

const addResourceActionFormBlank = {
  name: "",
  description: {
    text: "",
    url: "",
  },
  display: {
    property: {
      name: ""
    }
  },
};
const addResourceActionForm = ref({
  name: "",
  description: {
    text: "",
    url: "",
  },
  display: {
    property: {
      name: ""
    }
  },
});

const isEditResourceActionDialogVisible = ref(false);
const editResourceActionFormBlank = {
  hs: {
    id: "",
    resource: {
      action: {
        id: ""
      }
    }
  },
  name: "",
  description: {
    text: "",
    url: "",
  }
};

const editResourceActionForm = ref({
  hs: {
    id: "",
    resource: {
      action: {
        id: ""
      }
    }
  },
  name: "",
  description: {
    text: "",
    url: "",
  },
  display: {
    property: {
      name: ""
    }
  }
});

const resourceActions = ref([]);
const queriedResourceActionCount = ref(0);
const totalResourceActionCount = ref(0);
const envStore = useEnvStore();
const resourceActionProperties = ref([]);

const pageSizeOptions = ref([
  {label: '10', value: "10"},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  search: "",
  pageNumber: "1",
});

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getResourceActions()
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber, 10) > 1;
});

async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) - 1).toString();
  await getResourceActions();
}


const hasNextPage = computed(() => {
  return (
      queriedResourceActionCount.value < totalResourceActionCount.value)
});

async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) + 1).toString();
  await getResourceActions();
}

async function getResourceActions() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/resource-actions`, {
        params: {
          search: parameters.search,
          pageNumber: parameters.pageNumber,
          pageSize: parameters.pageSize,
        },
      })
  );
  if (err) {
    loading.value = false;
    return;
  }
  resourceActions.value = [];
  resourceActions.value = res.data.result.resourceActionList;
  queriedResourceActionCount.value = res.data.result.sqlQueriedResourceActionCurrentCount;
  totalResourceActionCount.value = res.data.result.sqlQueriedResourceActionTotalCount;
  loading.value = false;
}

async function getResourceAction(id) {
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/resource-actions/${id}`, {})
  );
  if (err) {
    ElNotification({
      title: "Error on getting resource action with ID " + id,
      type: "error",
    });
    return;
  }
  return res.data.result;
}

async function addResourceAction() {
  let err, res;
  [err, res] = await to(
      interceptor.post(
          `${envStore.apiUrls.dutypay}/api/v0/resource-actions`,
          addResourceActionForm.value
      )
  );
  if (err) {
    loading.value = false;
    ElNotification({
      title: "Error on adding resource-action",
      type: "error",
    });
    isAddResourceActionDialogVisible.value = false;
    return;
  }
  ElNotification({
    title: "ResourceAction successfully added.",
    type: "success",
  });
  loading.value = false;
  addResourceActionForm.value = cloneDeep(addResourceActionFormBlank);
  isAddResourceActionDialogVisible.value = false;
  await getResourceActions();
}

async function handleDeleteResourceAction(index, resourceAction) {
  loading.value = true;
  let id = resourceAction.hs.resource.action.id;
  try {
    await
        interceptor.delete(
            `${envStore.apiUrls.dutypay}/api/v0/resource-actions/${id}`,
            {}
        )
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on deleting Ressource",
      type: "error",
    });
    return;
  }
  ElNotification({
    title: "Ressource successfully deleted.",
    type: "success",
  });
  loading.value = false;
  resourceActionProperties.value = [];
  await getResourceActions();
}

async function handleEditResourceAction(index, resourceAction) {
  let id = resourceAction.hs.resource.action.id;
  let err, resourceActionFromServer;
  [err, resourceActionFromServer] = await to(getResourceAction(id));
  if (err) {
    ElNotification({
      title: "Error on getting resource-action " + id,
      type: "error",
    });
  }
  editResourceActionForm.value = resourceActionFromServer;
  isEditResourceActionDialogVisible.value = true;
}

async function editResourceAction() {
  console.log(editResourceActionForm.value)
  let err, res;
  [err, res] = await to(
      interceptor.patch(
          `${envStore.apiUrls.dutypay}/api/v0/resource-actions/${editResourceActionForm.value.hs.resource.action.id}`,
          editResourceActionForm.value
      )
  );
  if (err) {
    loading.value = false;
    ElNotification({
      title: "Error on editing resource-action",
      type: "error",
    });
    isEditResourceActionDialogVisible.value = false;
    return;
  }
  ElNotification({
    title: "ResourceAction successfully edited.",
    type: "success",
  });
  loading.value = false;
  editResourceActionForm.value = cloneDeep(editResourceActionFormBlank);
  isEditResourceActionDialogVisible.value = false;
  await getResourceActions();
}

onMounted(async () => {
  await getResourceActions();
});
</script>

<script>
import PaginationNavigationComponent from '../../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "ResourceActionsPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>
          ResourceActions &nbsp;
          <el-button type="success" @click="isAddResourceActionDialogVisible = true">
            Add
          </el-button>
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8" :xl="8" :lg="8" :sm="24" :xs="24" class="searchform">
        <el-form-item style="flex-grow: 1">
          <el-input
              v-model="parameters.search"
              @click="getResourceActions()"
              @change="parameters.pageNumber = 1"
              placeholder="Search for hs.id, name"
              :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getResourceActions"> Search</el-button>
        </el-form-item>
      </el-col>
      <el-col
          :span="16"
          :xl="16"
          :lg="16"
          :sm="24"
          :xs="24"
          class="pagesize"
          style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="ResourceActions per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="resourceActions" stripe style="width: 100%">
          <el-table-column prop="name" label="ResourceAction Name"/>
          <el-table-column prop="hs.resource.action.id" label="HubSystem ID"/>
          <el-table-column prop="description.text" label="Description"/>
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="handleEditResourceAction(scope.$index, scope.row)"
              >Edit
              </el-button
              >
              <el-button type="danger" @click="handleDeleteResourceAction(scope.$index, scope.row)"
              >Delete
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
        v-model="isAddResourceActionDialogVisible"
        title="Add ResourceActions"
        width="600px"
        align-center
        draggable
    >
      <el-form
          :model="addResourceActionForm"
          label-position="left"
          label-width="150px"
          style="max-width: 600px"
      >
        <el-form-item label="ResourceAction Name">
          <el-input v-model="addResourceActionForm.name" @change="getProperties"/>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="addResourceActionForm.description.text"/>
        </el-form-item>
        <el-form-item label="Description URL">
          <el-input v-model="addResourceActionForm.description.url"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAddResourceActionDialogVisible = false"
          >Cancel</el-button
          >
          <el-button type="primary" @click="addResourceAction">
            Add ResourceAction
          </el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog
        v-model="isEditResourceActionDialogVisible"
        :title="'Edit ResourceAction \'' + editResourceActionForm.name + '\''"
        width="600px"
        align-center
        draggable
    >
      <el-form
          :model="editResourceActionForm"
          label-position="left"
          label-width="150px"
          style="max-width: 600px"
      >
        <el-form-item label="ResourceAction Name">
          <el-input v-model="editResourceActionForm.name" disabled/>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="editResourceActionForm.description.text"/>
        </el-form-item>
        <el-form-item label="Description URL">
          <el-input v-model="editResourceActionForm.description.url"/>
        </el-form-item>
        <!--<el-form-item label="Deleted?">
          <el-checkbox v-model="editResourceActionForm.deleted" :checked="!!editResourceActionForm.deleted">Deleted?</el-checkbox>
        </el-form-item>-->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isEditResourceActionDialogVisible = false"
          >Cancel</el-button
          >
          <el-button type="primary" @click="editResourceAction">
            Edit ResourceAction
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
{
  "en-US": {
  }
}
</i18n>
